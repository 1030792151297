<template>
  <qtm-combobox
    v-bind="$attrs"
    ref="combobox"
    :delimiters="[',', ' ']"
    :filter-keys="['raw.name', 'raw.email']"
    hide-selected
    item-value="email"
    item-title="email"
    :items="addresses"
    :menu-props="{ closeOnContentClick: true }"
    multiple
    :readonly="readonly"
    :return-object="false"
    :model-value="modelValue"
    @update:model-value="cleanAndEmit"
  >
    <template v-slot:prepend-item>
      <v-list-item>
        <v-list-item-title>
          Type a new address and press <kbd>space</kbd> or <kbd>,</kbd>
          or <kbd>Enter</kbd> or <kbd>Tab</kbd> to add it.
        </v-list-item-title>
      </v-list-item>
      <v-divider />
    </template>

    <template v-slot:selection="{ attrs, item, selected }">
      <v-chip
        :key="JSON.stringify(item)"
        :closable="!readonly"
        size="small"
        v-bind="attrs"
        :color="isValidEmail(item.title) ? 'interactive' : 'red'"
        :input-value="selected"
        variant="flat"
        @click:close.stop="removeItem(item.title)"
      >
        {{ item.title }}
      </v-chip>
    </template>

    <template v-slot:item="data">
      <template v-if="typeof data.item !== 'object'">
        <v-list-item v-bind="data.props">
          {{ data.item }}
        </v-list-item>
      </template>
      <template v-else>
        <v-list-item v-bind="data.props">
          <v-list-item-subtitle>
            {{ data.item.raw.name }} <span v-if="data.item.raw.type">({{ data.item.raw.type }})</span>
          </v-list-item-subtitle>
        </v-list-item>
      </template>
    </template>
  </qtm-combobox>
</template>

<script setup lang="ts">
export interface Props {
  addresses?: { name: string, email: string }[]
  modelValue: string[]
  readonly?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  addresses: () => [],
})
const emit = defineEmits(['update:model-value'])

const cleanAndEmit = (emails: string[]) => {
  emit('update:model-value', emails.filter(email => isValidEmail(email)))
}

const isValidEmail = (email: string) => {
  // eslint-disable-next-line max-len
  const regex = /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/

  return regex.test(email)
}

const removeItem = (removedEmail: string) => {
  emit('update:model-value', props.modelValue.filter(email => email !== removedEmail))
}
</script>

<style scoped>
kbd {
  background-color: black;
  border-radius: 4px;
  color: white;
  padding: 2px 4px;
}
.v-select.v-input--dense .v-chip {
  margin: 3px 4px;
}
</style>
